@font-face {
  font-family: "ui-font-1";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Serif_JP/NotoSerifJP-Light.otf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ui-font-2";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Serif_JP/NotoSerifJP-ExtraLight.otf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ui-font-3";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Sans_JP/NotoSansJP-Regular.ttf");

  font-style: normal;
}
@font-face {
  font-family: "ui-font-4";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Sans_JP/NotoSansJP-Medium.ttf");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "ui-font-5";
  src: local("ui-font-1"), url("../../../public/fonts/Noto_Sans_JP/NotoSansJP-Light.ttf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ui-font-6";
  src: local("ui-font-6"), url("../../../public/fonts/BIZUDPMincho-Bold.ttf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ui-font-7";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Sans_JP/NotoSansJP-ExtraBold.ttf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ui-font-8";
  src: local("ui-font-1"),
    url("../../../public/fonts/Noto_Serif_SC/NotoSerifSC-Regular.otf");
  font-weight: medium;

  font-style: normal;
}
@font-face {
  font-family: "ui-font-9";
  src: local("ui-font-1"),
    url("../../../public/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Medium.ttf");
  font-weight: medium;

  font-style: normal;
}
// @font-face {
//   font-family: "ui-font-3";
//   src: local("sans-serif"), url(""), format("opentype");
//   font-weight: medium;
//   font-style: normal;
// }

// @font-face {
//   font-family: "ui-font-4";
//   src: local("yu-mincho-medium"), url(""), format("opentype");
//   font-weight: medium;
//   font-style: normal;
// }
