@import "./variables.scss";
@import "./spacing.scss";
@import "./breakpoint";
@import "./reset";
@import "./font";
html,
body {
  margin: 0;
  padding: 0;
}  
* {
    box-sizing: border-box;
}


